<template>
  <div>
    <UserInfo />
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="800"
      filename="HISTORIAL OFTALMOLOGÍA"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="table-comunidad-pdf">
          <div class="options-agregarPaciente">
            <h1 class="title-consultorioMovil">Lista de pacientes consultorio móvil</h1>
          </div>
          <el-table :data="tableData">
            <el-table-column
              prop="firstName"
              label="Nombre"
              width="180"/>
            <el-table-column
              prop="lastName"
              label="Apellido"
              width="180"/>
            <el-table-column
              prop="birthdate"
              label="Fecha de nacimiento"/>
          </el-table>
        </div>
      </section>
    </vue-html2pdf>
    <div class="container-datosDeRegistro">
      <h1 class="title-consultorioMovil">Datos de registro</h1>
      <el-form
        :model="FormProject"
        :rules="rules"
        status-icon
        ref="FormProject"
        label-width="120px"
        class="demo-FormProject"
        label-position="top"
      >
      <el-row :gutter="24" class="row-consultorioMovil" justify="space-between">
        <el-col
          :xs="24"
          :sm="12"
          :md="4"
          :lg="4"
        >
          <el-form-item label="Nombre" prop="firstName">
            <el-input
              v-model="FormProject.firstName"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="Apellido" prop="lastName">
            <el-input
              v-model="FormProject.lastName"
              :disabled="!FormProject.firstName"
            />
          </el-form-item>
          <el-form-item
            label="Fecha de nacimiento"
            prop="birthdate"
          >
            <el-date-picker
              type="date"
              value-format="yyyy-MM-dd"
              @change="edadPermitida()"
              placeholder="Seleccione una fecha"
              v-model="FormProject.birthdate"
              style="width: 100%;"
              :disabled="!FormProject.lastName"
            />
          </el-form-item>
            <div v-if="isNew || isPatient">
              <el-form-item label="Sexo" prop="sex">
                <el-select
                  filterable
                  v-model="FormProject.sex"
                  clearable
                  placeholder="Seleccionar">
                <el-option
                  v-for="item in sexos"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
              </el-form-item>
              <el-form-item label="Padecimientos" prop="padecimientos">
                <el-select
                  multiple
                  filterable
                  v-model="FormProject.padecimientos"
                  @change="handlePadecimientos()"
                  clearable
                  placeholder="Seleccionar">
                <el-option
                  v-for="(item, idx) in padecimientosOptions"
                  :key="idx"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
              </el-form-item>
              <el-form-item label="Telefono" prop="phone">
                <el-input
                  v-model="FormProject.phone"
                />
              </el-form-item>
              <el-form-item label="Referido" prop="referido">
                <el-select
                  filterable
                  v-model="FormProject.referido"
                  clearable
                  placeholder="Seleccionar">
                <el-option
                  v-for="item in referidoOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="FormProject.referido === 'Fundación +Luz'"
                label="Servicio referido"
                prop="especialidad"
              >
                <el-select
                  filterable
                  v-model="FormProject.especialidad"
                  clearable
                  placeholder="Seleccionar">
                <el-option
                  v-for="item in especialidades"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button
                  id="registrar-button"
                  @click="submitForm('FormProject')"
                >
                  Registrar
                </el-button>
              </el-form-item>
            </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="16"
          :md="16"
          :lg="12"
          class="pickerDate">
          <div class="options-agregarPaciente">
            <h1 class="title-consultorioMovil">Lista de pacientes </h1>
            <el-button @click="generateReport()">Imprimir</el-button>
          </div>
          <el-table
            :data="tableData"
            style="width: 100%; height: 800px; overflow-y: auto;">
            <el-table-column
              prop="firstName"
              label="Nombre"
              width="180"/>
            <el-table-column
              prop="lastName"
              label="Apellido"
              width="180"/>
            <el-table-column
              prop="birthdate"
              label="Fecha de nacimiento"/>
          </el-table>
        </el-col>
      </el-row>
      </el-form>
    </div>
    <el-dialog
      title="Paciente registrado"
      :visible.sync="dialogIsPatient"
      width="50%"
    >
      <span>El ciudadano ya es paciente de fundación +Luz, desea seguir con el registro?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="seguirConRegistro(false)">Cancelar</el-button>
        <el-button type="primary" @click="seguirConRegistro(true)">
          Seguir con el registro
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-if="dialogCalendarJornadaClinica"
      title="Fecha de la jornada clinica"
      :visible.sync="dialogCalendarJornadaClinica"
      width="50%"
    >
        <Calendar
          v-on:selected-hour="selectedHour"
          v-on:selected-date="selectedFecha"
        />
        <el-button @click="handlerJornadaClinica(false)">Cancelar</el-button>
        <el-button type="primary" @click=" handlerJornadaClinica(true)">
          Seguir con el registro
        </el-button>
    </el-dialog>
    <el-dialog
      v-if="dialogCalendarEspecialidad"
      title="Fecha de cita de especialidad"
      :visible.sync="dialogCalendarEspecialidad"
      width="50%"
    >
        <Calendar
          :selectedDateDefault="today"
          v-on:selected-hour="selectedHour"
          v-on:selected-date="selectedFecha"
          :disabledsEspeciality="reservedHours"
        />
        <el-button @click="handlerEspecialidad(false)">Cancelar</el-button>
        <el-button type="primary" @click=" handlerEspecialidad(true)">
          Aceptar
        </el-button>
    </el-dialog>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import Calendar from '../../../components/Calendar';
import UserInfo from '../../../components/userInfo';
import PatientAPI from '../../../API/comunidad/patient';
import SpecialtyAPI from '../../../API/globales/especialidades';
import comunidadProyectoApi from '../../../API/comunidad/proyectos';

const moment = require('moment');

export default {
  data() {
    // eslint-disable-next-line consistent-return
    const checkAge = (rule, value, callback) => {
      const edad = this.edadPermitida();
      if (!edad) {
        return callback(new Error('Seleccione una fecha'));
      }
      setTimeout(() => {
        if (!Number.isInteger(edad)) {
          callback(new Error('Please input digits'));
        } else if (edad < 18) {
          callback(new Error('La edad debe ser mayor a 18 años'));
        } else if (edad > 120) {
          callback(new Error('La edad debe ser menor a 120 años'));
        } else {
          this.validateName();
          callback();
        }
      }, 1000);
    };
    return {
      today: null,
      dialogCalendarEspecialidad: false,
      dialogCalendarJornadaClinica: false,
      reservedHours: [],
      isNew: false,
      isPatient: false,
      dialogIsPatient: false,
      selectedDate: null,
      proyectoId: null,
      registerProcess: false,
      proyectoIdJornadaClinica: null,
      sexos: [{ label: 'Femenino', value: 'Femenino' }, { label: 'Masculino', value: 'Masculino' }],
      hour: null,
      date: null,
      padecimientosOptions: [
        {
          value: 'Hipertención',
          label: 'Hipertención',
        },
        {
          value: 'Diabetes',
          label: 'Diabetes',
        },
      ],
      patientInfoObject: {
        diabetes: false,
        arterialHypertension: false,
      },
      referidoOptions: [
        {
          value: 'Fundación +Luz',
          label: 'Fundación +Luz',
        },
        {
          value: 'No referido',
          label: 'No referido',
        },
      ],
      especialidades: [
        {
          value: 0,
          label: 'Jornada clínica',
        },
        {
          value: 100,
          label: 'Consulta general',
        },
      ],
      errorEdad: null,
      tableData: [],
      FormProject: {
        name: 'consultorioMovil',
        firstName: null,
        lastName: null,
        birthdate: null,
        phone: '',
        sex: '',
        padecimientos: [],
        referido: null,
        especialidad: null,
        patientId: null,
      },
      rules: {
        municipio: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        firstName: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        lastName: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        birthdate: [
          {
            required: true,
            trigger: 'change',
            validator: checkAge,
          },
        ],
        sex: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        phone: [{ required: true, message: '¡Campo requerido!', trigger: 'blur' }],
      },
    };
  },
  components: {
    VueHtml2pdf,
    UserInfo,
    Calendar,
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    selectedHour(value) {
      this.hour = value;
    },
    async selectedFecha(value) {
      this.date = value;
    },
    async submitForm(formName) {
      // eslint-disable-next-line consistent-return
      this.registerProcess = true;
      // eslint-disable-next-line consistent-return
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.register();
        } else {
          return false;
        }
      });
    },
    handlerJornadaClinica(seguir) {
      if (seguir) {
        this.createJornadaClinica();
      } else {
        this.resetFields();
      }
    },
    handlerEspecialidad(seguir) {
      if (seguir) {
        this.createEspecialidad();
      } else {
        this.date = null;
        this.dialogCalendarEspecialidad = false;
      }
    },
    seguirConRegistro(seguir) {
      if (seguir) {
        if (this.FormProject.firstName != null && this.FormProject.lastName != null) {
          this.dialogIsPatient = false;
          this.isPatient = true;
        }
      } else {
        this.resetFields();
      }
    },
    resetFields() {
      this.registerProcess = false;
      this.dialogIsPatient = false;
      this.isPatient = false;
      this.isNew = false;
      this.dialogCalendarJornadaClinica = false;
      this.dialogCalendarEspecialidad = false;
      this.proyectoIdJornadaClinica = null;
      this.patientInfoObject = {};
      this.FormProject = {
        name: 'consultorioMovil',
        firstName: null,
        lastName: null,
        birthdate: null,
        phone: '',
        sex: '',
        padecimientos: [
          {
            label: 'Diabetes',
            value: null,
          },
          {
            label: 'Hipertension',
            value: null,
          },
        ],
        referido: null,
        especialidad: null,
        patientId: null,
      };
      this.hour = null;
      this.date = null;
      this.$loading().close();
    },
    async getPatientList() {
      try {
        const request = await PatientAPI.getPatients(this.proyectoId);
        if (request) {
          const patiens = request;
          await patiens.forEach((element) => {
            this.tableData.push(element.patient.info[0]);
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    handlePadecimientos() {
      // eslint-disable-next-line no-unused-expressions
      this.FormProject.padecimientos.includes('Diabetes') ? this.patientInfoObject.diabetes = true : this.patientInfoObject.diabetes = false;
      // eslint-disable-next-line no-unused-expressions
      this.FormProject.padecimientos.includes('Hipertención') ? this.patientInfoObject.arterialHypertension = true : this.patientInfoObject.arterialHypertension = false;
    },
    async getAllEspecialidades() {
      try {
        const request = await SpecialtyAPI.getEspecialidades();
        if (request) {
          const especialidades = request;
          especialidades.forEach((element) => {
            this.especialidades.push({
              value: element.id,
              label: element.name,
            });
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getReservedHours() {
      try {
        this.$loading();
        if (this.date) {
          this.reservedHours = [];
          const { especialidad } = this.FormProject;
          const response = especialidad === 100
            ? await await this.axios(`patient/subsecuente/md/reserved-schedules/?date=${this.date}`)
            : await this.axios(`patient/subsecuente/esp/reserved-schedules/${especialidad}?date=${this.date}`);
          const { data } = response;
          if (Array.isArray(data)) this.reservedHours = data;
        }
        this.$loading().close();
      } catch (error) {
        this.$loading().close();
        this.$errorNotify(error);
      }
    },
    // eslint-disable-next-line consistent-return
    async validateName() {
      try {
        if (this.registerProcess !== true) {
          const {
            firstName,
            lastName,
          } = this.FormProject;
          if (firstName !== null && lastName !== null) {
            const fechaNacimient = this.FormProject.birthdate?.toString();
            if (firstName && lastName && fechaNacimient) {
              const exists = await this.axios.get(`patient/specialty/${firstName}/${lastName}/${fechaNacimient}`);
              if (exists.data.length > 0) {
                this.FormProject.patientId = exists.data[0].patientId;
                this.isNew = false;
                this.dialogIsPatient = true;
                return '';
              }
              this.isNew = true;
              this.isPatient = false;
              return this.$message({
                message: 'Ciudadano aprobado',
                type: 'success',
              });
            }
          }
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async postPatientComunidad() {
      try {
        let referidoLugar = null;
        if (this.FormProject.referido === 'No referido') {
          this.FormProject.referido = false;
          referidoLugar = 'No referido';
        } else {
          this.FormProject.referido = true;
        }

        if (this.FormProject.especialidad === 0) {
          referidoLugar = 'Jornada Clinica';
        } else {
          // Buscar el nombre de la especialidad en el arreglo de especialidades
          // eslint-disable-next-line max-len
          const especialidad = this.especialidades.find((esp) => esp.value === this.FormProject.especialidad);
          referidoLugar = especialidad.label;
        }
        const data = {
          ...this.FormProject,
          referidoLugar,
        };
        const request = await PatientAPI.postPatientComunidad(this.proyectoId, data);
        if (request) {
          this.tableData.unshift(this.FormProject);
          this.$notify({
            type: 'success',
            message: '¡Paciente agregado correctamente!',
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async postPatientComunidadJornadaClinica() {
      try {
        if (this.FormProject.referido === 'No referido') {
          this.FormProject.referido = false;
        } else {
          this.FormProject.referido = true;
        }
        const data = this.FormProject;
        const request = await PatientAPI.postPatientComunidad(this.proyectoIdJornadaClinica, data);
        if (request) {
          this.$notify({
            type: 'success',
            message: '¡Paciente agregado correctamente a jornada clinicas!',
          });
        }
        this.resetFields();
      } catch (error) {
        // console.log(error);
      }
    },
    async putPatient() {
      try {
        const dataPatientInfo = {
          gender: this.FormProject.sex,
          ...this.patientInfoObject,
        };
        const res = await PatientAPI.putPatientProfile(this.FormProject.patientId, dataPatientInfo);
        if (res) await this.postPatientComunidad();
      } catch (error) {
        // console.log(error);
      }
    },
    async postPatient() {
      try {
        const dataPatientInfo = {
          gender: this.FormProject.sex,
          ...this.patientInfoObject,
          ...this.FormProject,
        };
        const request = await PatientAPI.postPatient(dataPatientInfo);
        if (request) {
          this.FormProject.patientId = request.patient;
          const patientId = request.patient;
          if (patientId) {
            await PatientAPI.postPatientProfile(patientId, dataPatientInfo);
            await this.postPatientComunidad();
          }
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async createJornadaClinica() {
      try {
        this.$loading();
        const jornadaObject = {
          name: 'jornadaClinica',
          municipio: 'Tijuana',
          direccion: 'Fundacion +Luz',
          delegacion: 1,
          colonia: 1,
          referido: true,
          cp: 1,
          alianza: 1,
          fecha: this.date,
          hora: this.hour,
        };
        const request = await comunidadProyectoApi.postcomunidadProyecto(jornadaObject);
        if (request) {
          await this.postPatientComunidadJornadaClinica();
          this.$notify({
            type: 'success',
            message: '¡Jornada clinica creada correctamente!',
          });
        }
        this.$loading().close();
      } catch (error) {
        this.$loading().close();
        // console.log(error);
      }
    },
    async createEspecialidad() {
      try {
        this.$loading();
        this.FormProject.name = 'consultorioMovil';
        const data = this.FormProject;
        if (this.FormProject.especialidad !== 100) {
          // eslint-disable-next-line no-unused-expressions
          this.isPatient === true ? await this.putPatient() : await this.postPatient();
          const request = await SpecialtyAPI.postEspecialidad({
            type: 'PRIMERA CITA',
            location: '',
            date: this.date,
            time: this.hour,
            specialityId: data.especialidad,
            procedure: null,
            subject: 'Especialidad',
            comments: 'Referido desde consultorio Móvil',
            patientId: data.patientId,
          });
          if (request) {
            this.$notify({
              type: 'success',
              message: '¡Cita de especialidad creada correctamente!',
            });
          }
        } else {
          // eslint-disable-next-line no-unused-expressions
          this.isPatient === true ? await this.putPatient() : await this.postPatient();
          const request = await SpecialtyAPI.postConsultaGeneral({
            type: 'PRIMERA CITA',
            location: '',
            date: this.date,
            time: this.hour,
            procedure: null,
            subject: 'Médico',
            comments: 'Referido desde consultorio Móvil',
            patientId: data.patientId,
          });
          if (request) {
            this.$notify({
              type: 'success',
              message: '¡Cita de consulta general creada correctamente!',
            });
          }
        }
        this.resetFields();
        this.$loading().close();
      } catch (error) {
        this.$loading().close();
      }
    },
    async register() {
      try {
        this.$loading();
        if (this.FormProject.refirido !== 'No referido') {
          if (this.FormProject.especialidad === 0) {
            // eslint-disable-next-line no-unused-expressions
            this.isPatient === true ? await this.putPatient() : await this.postPatient();
            const request = await comunidadProyectoApi.getcomunidadProyectoActivo('jornadaClinica');
            if (request.length > 0) {
              this.proyectoIdJornadaClinica = request[0].id;
              await this.postPatientComunidadJornadaClinica();
            } else {
              this.dialogCalendarJornadaClinica = true;
            }
          } else {
            this.dialogCalendarEspecialidad = true;
          }
        } else {
          // eslint-disable-next-line no-unused-expressions
          this.isPatient === true ? await this.putPatient() : await this.postPatient();
        }
        // this.resetFields();
        this.$loading().close();
      } catch (error) {
        this.$notifyErro(error);
      }
    },
    edadPermitida() {
      // condiciones el ciudadano debe ser mayor a 18
      const hoy = new Date();
      const cumpleanos = new Date(this.FormProject.birthdate);
      let edad = hoy.getFullYear() - cumpleanos.getFullYear();
      const mes = hoy.getMonth() - cumpleanos.getMonth();

      if (mes < 0 || (mes === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }
      if (edad < 18) {
        this.errorEdad = 'No cumple con la edad requeridad';
      }
      return edad;
    },
  },
  async created() {
    this.$loading();
    this.proyectoId = this.$route.params.proyectoId;
    const date = new Date();
    this.today = moment(date).format('YYYY-MM-DD');
    await this.getPatientList();
    await this.getAllEspecialidades();
    this.$loading().close();
  },
  watch: {
    async selectedDate(val) {
      if (val) {
        const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        this.FormProject.fecha = date;
      }
    },
    date(val) {
      if (val) {
        if (this.FormProject.especialidad !== 0) this.getReservedHours();
      }
    },
    // eslint-disable-next-line func-names
    'FormProject.firstName': async function (val) {
      if (val.length >= 5 && val != null) await this.validateName();
    },
    // eslint-disable-next-line func-names
    'FormProject.lastName': async function (val) {
      if (val.length >= 5 && val != null) await this.validateName();
    },
  },
};
</script>

<style lang="scss" scoped>
.title-consultorioMovil {
  margin: 0px;
  color: #8F70C7;
}
h3 {
  margin: 0px;
  color: #3e3e3e;
}
.button-agendar {
  background-color: #8F70C7;
  // border: none;
  color: #fff;
}
.row-consultorioMovil {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}
#registrar-button {
  background-color: #8F70C7;
  border: none;
  color: #fff;
  font-size: 16px;
  min-width: 210px;
}
</style>

<style lang="scss">
.table-comunidad-pdf {
  width: 100%;
  padding: 20px;
  margin-bottom: 350px;
}
  .el-button {
    align-self: end;
    background-color: #8F70C7;
    color: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .options-agregarPaciente {
    display: flex;
    justify-content: space-between;
  }
.el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .el-row {
    width: 200px;
  }
}
.container-datosDeRegistro {
  padding: 20px;
}
.el-form--label-top .el-form-item__label {
  padding: 0px;
}/* width */
.el-form-item__label {
  line-height: 30px;
  font-weight: bold;
  color: #8F70C7;
  font-size: 16px;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(157, 108, 229);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(123, 59, 218, 0.855);
  border-radius: 12px;
}
</style>
